import React from "react";
import styles from "./HomePage.module.css";

const HomePage = () => (
  <article className={styles.homePage}>
    <h1 className={styles.welcomeMsg}>
      Welcome to Ling’s Wing Chun Kung Fu Academy!
    </h1>
    <section className={styles.description}>
      <p>
        We offer classes in Yang-style Tai Chi, Wing Chun and Lion Dance.
        Self-defense classes are also available on request.
      </p>

      <p>
        At Ling's Wing Chun Kung Fu Academy, we value respect, discipline, and
        empowerment. All of our classes are mixed styles, and children and
        adults are in the same classes. Our goal is to bring families together.
        When families train together, they build a stronger bond with each
        other.
      </p>
      <p>
        For kids, we focus on cultivating respect, discipline, defense, and
        physical conditioning. For adults, we try to accommodate to specific
        needs and goals. Whether they want to accomplish health goals, increase
        their confidence, learn how to defend themselves, or just want to learn
        about a different culture, we try out best to achieve whatever they set
        their minds to.
      </p>
    </section>

    <section className={styles.media}>
      <img
        className={styles.groupPic}
        src={require("../../assets/group-pic.jpg")}
        alt="Ling's Wing Chun students"
      />
      {/*
      <iframe
        className={styles.performanceVideo}
        title="2017 Glens Falls Senior Center's Fall Festival Fundraiser Performance"
        src="https://www.youtube-nocookie.com/embed/5sxsncLQo-k"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>*/}
    </section>
  </article>
);

export { HomePage };
