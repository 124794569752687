import React from "react";
import styles from "../../styles/classPage.module.css";

const WingChunPage = () => (
  <article className={styles.page}>
    <section className={styles.details}>
      <h1>Ip Man Wing Chun</h1>
      <p>
        Wing Chun is a simple and direct fighting art that specializes in close
        quarter combat. This style teaches you to rely on your skill rather than
        strength against a bigger and stronger opponent.
      </p>
      <p>
        There are a few different styles of Wing Chun. Ling’s Wing Chun Kung Fu
        Academy follows the Ip Man Wing Chun lineage. Ip Man had many students,
        his most famous being Bruce Lee. Bruce Lee taught Wing Chun in
        California during the 1960’s. Bruce used Wing Chun and other fighting
        systems to create his own Jeet Kune Do style. The Chinese Military as
        well as some European countries have adopted the Wing Chun fighting
        system into their training.
      </p>
    </section>
    <img alt="Wing chun forms" src={require("../../assets/wing-chun.jpg")} />

    <table className={styles.scheduleTable}>
      <caption>
        <h2>Wing Chun class schedule</h2>
      </caption>
      <tbody>
        <tr>
          <td>Monday, Wednesday, Friday</td>
          <td>6:30 p.m. &ndash; 7:30 p.m.</td>
        </tr>
        <tr>
          <td>Saturday</td>
          <td>11:45 a.m. &ndash; 12:45 p.m.</td>
        </tr>
      </tbody>
    </table>
  </article>
);

export { WingChunPage };
