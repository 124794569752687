import React, { useState } from "react";
import styles from "./Navbar.module.css";
import { Link } from "react-router-dom";

const Classes = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen((isOpen) => !isOpen);
  const openMenu = () => setIsOpen(true);
  const closeMenu = () => setIsOpen(false);
  return (
    <div
      onMouseEnter={openMenu}
      onMouseLeave={closeMenu}
      className={styles.classes}
    >
      <span className={styles.classesHeader} onClick={toggleOpen}>
        Classes
      </span>
      {isOpen && (
        <ul className={styles.classList}>
          <li>
            <Link to="/wing-chun">Wing chun</Link>
          </li>
          <li>
            <Link to="/tai-chi">Tai chi</Link>
          </li>
          <li>
            <Link to="/qi-gong-and-chair-exercises">
              Qi gong and chair exercises
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

const Navbar = () => {
  return (
    <nav className={styles.navbar}>
      <ul className={styles.links}>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="about-us">About us</Link>
        </li>
        <li>
          <Classes />
        </li>
        {/*<li>
                  <Link to="/pictures">Pictures</Link>
              </li>*/}
        {/*<li>
          <Link to="/videos">Videos</Link>
        </li>*/}
        <li>
          <Link to="contact-us">Contact us</Link>
        </li>
      </ul>
    </nav>
  );
};

export { Navbar };
