import React from "react";
import styles from "./AboutUsPage.module.css";

const AboutUsPage = () => {
  return (
    <article className={styles.aboutUsPage}>
      <figure className={styles.picOfSifu}>
        <img alt="Sifu Sam Ling" src={require("../../assets/sifu.png")} />
        <figcaption>Sifu Sam Ling</figcaption>
      </figure>
      <section className={styles.details}>
        {/* <h2>About Ling's Wing Chun Kung Fu Academy</h2> */}
        <h2>About Sifu</h2>
        <p>
          Born in Hong Kong, Sifu Sam Ling immigrated to the United States in
          1974 with the family, settling in South Colonie, Albany. As a
          youngster, Sifu Sam had a great interest in kung fu and would always
          practice with his friends who had formal kung fu training.
        </p>
        <p>
          In 1997, Sifu Ling began formally studying under Sifu Steven Nacua. He
          trained in Choy Lee Fut, Hop Gar, and White Crane Kung Fu with Sifu
          Nacua.
        </p>
        <p>
          In 1999, Sifu Ling began studying Wing Chun Kung Fu under Dr. Jack
          Ling (Leung Sheung's student). Sifu Sam Ling is from the Ip Man, Leung
          Sheung Wing Chun lineage (3rd generation student). Along with Wing
          Chun, Sifu Ling began to study Yang Style Tai Chi under Dr. S’Keng Pan
          in 2006.
        </p>
      </section>
    </article>
  );
};

export { AboutUsPage };
