import React from "react";
import styles from "./App.module.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { Navbar } from "../Navbar";
import { AboutUsPage } from "../AboutUsPage";
import { WingChunPage } from "../WingChunPage";
import { TaiChiPage } from "../TaiChiPage";
import { QiGongAndChairExercisesPage } from "../QiGongAndChairExercisesPage";
import { ContactUsPage } from "../ContactUsPage";
import { HomePage } from "../HomePage";

function App() {
  return (
    <BrowserRouter>
      <div className={styles.App}>
        <header className={styles.header}>
          <img
            className={styles.logo}
            alt="Ling's Wing Chun logo"
            src={require("../../assets/lingswingchun-logo2.jpg")}
          />
          <Navbar />
        </header>
        <main className={styles.mainContent}>
          <Switch>
            <Route path="/about-us">
              <AboutUsPage />
            </Route>

            <Route path="/wing-chun">
              <WingChunPage />
            </Route>

            <Route path="/tai-chi">
              <TaiChiPage />
            </Route>

            <Route path="/qi-gong-and-chair-exercises">
              <QiGongAndChairExercisesPage />
            </Route>

            <Route path="/contact-us">
              <ContactUsPage />
            </Route>

            <Route path="/" exact>
              <HomePage />
            </Route>

            <Route>
              <HomePage />
            </Route>
          </Switch>
        </main>
      </div>
    </BrowserRouter>
  );
}

export { App };
