import React from "react";
import styles from "../../styles/classPage.module.css";

const QiGongAndChairExercisesPage = () => (
  <article className={styles.page}>
    <section className={styles.details}>
      <h2>Tai Chi Qi Gong</h2>
      <p>
        In this class, you’ll get a full body workout from your head down to
        your feet. With slow, gentle stretching movements and deep breathing,
        you will achieve a calm mind with relaxing music.
      </p>
      <p>
        Tai chi promotes increases joint mobility, balance, and muscle and bone
        strength. Light and easy cardio exercise through these movements will
        also strengthen heart and lung function. Practicing Tai Chi Qi Gong on a
        regular basis can improve and maintain one’s mental and physical
        well-being.
      </p>
      <h2>Tai Chi Qi Gong and Chair Exercises</h2>

      <p>
        During the first half of this class we do stationary stretches and
        breathing exercises while in an upright position. This can also be done
        while sitting down.
      </p>
      <p>
        During the second half of class we do chair exercises which involve
        slower stretching exercises and some self massage while sitting.
      </p>
    </section>
    <img
      alt="qi gong and chair exercises"
      src={require("../../assets/qi-gong-chair-exercises.png")}
    />

    <table className={styles.scheduleTable}>
      <caption>
        <h2>Qi gong class schedule</h2>
      </caption>
      <tbody>
        <tr>
          <th>Qi gong exercises</th>
        </tr>
        <tr>
          <td>Monday, Wednesday, Friday</td>
          <td>5:30 p.m. &ndash; 6:30 p.m.</td>
        </tr>
        <tr>
          <th>Qi gong and chair exercises</th>
        </tr>
        <tr>
          <td>Tuesday, Thursday</td>
          <td>11:00 a.m. &ndash; 12:00 p.m.</td>
        </tr>
      </tbody>
    </table>
  </article>
);

export { QiGongAndChairExercisesPage };
