import React from "react";
import styles from "../../styles/classPage.module.css";

const TaiChiPage = () => (
  <article className={styles.page}>
    <section className={styles.details}>
      <h1>Yang-style Tai Chi</h1>
      <p>
        Tai Chi is a very low impact form of exercise. It is not strenuous to
        the joints or muscles. Tai Chi is especially great for senior citizens.
      </p>
      <p>
        Tai Chi is soft and graceful to watch, and there are many styles of Tai
        Chi. The most popular styles are Yang, Chen, Wu, Sun. We teach the
        Yang-style Tai Chi form which is standardized by the Chinese government.
        We teach the Yang style for health reasons <em>only</em>. We will,
        however, illustrate the meaning behind each of the movements.
      </p>
    </section>
    <img alt="Tai Chi forms" src={require("../../assets/tai-chi.png")} />
    {/* <iframe
      className={styles.video}
      title="Tai Chi forms"
      width="560"
      height="315"
      src="https://www.youtube.com/embed/uPWwIVgNBY8"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
    */}
    <table className={styles.scheduleTable}>
      <caption>
        <h2>Tai Chi class schedule</h2>
      </caption>
      <tbody>
        <tr>
          <th>Yang-Style Tai Chi and Qi-Gong</th>
        </tr>
        <tr>
          <td>Monday, Tuesday, Thursday</td>
          <td>9:30 a.m. &ndash; 10:30 a.m.</td>
        </tr>
        <tr>
          <td>Monday, Wednesday, Friday</td>
          <td>11:00 a.m. &ndash; 12:00 p.m.</td>
        </tr>
        <tr>
          <td>Tuesday, Thursday</td>
          <td>5:30 p.m. &ndash; 6:30 p.m.</td>
        </tr>
        <tr>
          <td>Saturday</td>
          <td>10:35 a.m. &ndash; 11:35 a.m.</td>
        </tr>
        <tr>
          <th>Beginner class</th>
        </tr>
        <tr>
          <td>Tuesday, Thursday</td>
          <td>6:30 p.m. &ndash; 7:30 p.m.</td>
        </tr>
        <tr>
          <td>Saturday</td>
          <td>9:30 a.m. &ndash; 10:30 a.m.</td>
        </tr>
      </tbody>
    </table>
  </article>
);

export { TaiChiPage };
