import React, { useState } from "react";
import styles from "./ContactUsPage.module.css";

// const serviceId = "default_service";
// const templateId = "lingswingchun";
// const userId = "user_02mHy0OdPUinVmjtJag9y";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [showRequiredMsg, setShowRequiredMsg] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);

  // const submitHandler = async () => {
  //   try {

  //     setShowSuccessMsg(true);
  //     setName("");
  //     setEmail("");
  //     setSubject("");
  //     setMessage("");
  //     showRequiredMsg && setShowRequiredMsg(false);
  //     showErrorMsg && setShowErrorMsg(false);
  //   } catch (e) {
  //     console.log(e);
  //     setShowErrorMsg(true);
  //   }
  // };

  return (
    <form
      className={styles.contactForm}
      onSubmit={(e) => {
        e.preventDefault();
        if (name && email && subject && message) {
          // submitHandler();
        } else {
          setShowRequiredMsg(true);
        }
      }}
    >
      <label htmlFor="name">Name:</label>
      <input
        type="text"
        id="name"
        value={name}
        onChange={(e) => {
          e.preventDefault();
          setName(e.target.value);
        }}
        required
      />

      <label htmlFor="email">Email:</label>
      <input
        type="email"
        id="email"
        value={email}
        onChange={(e) => {
          e.preventDefault();
          setEmail(e.target.value);
        }}
        required
      />

      <label htmlFor="subject">Subject:</label>
      <input
        type="text"
        id="subject"
        value={subject}
        onChange={(e) => {
          e.preventDefault();
          setSubject(e.target.value);
        }}
        required
      />

      <label htmlFor="message">Message:</label>
      <textarea
        // type="textarea"
        id="message"
        value={message}
        onChange={(e) => {
          e.preventDefault();
          setMessage(e.target.value);
        }}
        required
      />

      {showRequiredMsg && <p>All fields required.</p>}
      {showSuccessMsg && <p>Email successfully sent!</p>}
      {showErrorMsg && <p>There was an error. Try again.</p>}

      <button
        type="submit"
        className={styles.submitButton}
        // onClick={e => {
        //   e.preventDefault();
        // }}
      >
        Submit
      </button>
    </form>
  );
};

const ContactUsPage = () => {
  return (
    <article className={styles.contactUsPage}>
      <div className={styles.container}>
        <section className={styles.socialMedia}>
          <h2>Social media</h2>
          <p>
            Check out our{" "}
            <a
              className={styles.socialMediaLink}
              href="https://www.facebook.com/LingsWingChun/"
              rel="noopener noreferrer"
              // target="_blank"
            >
              Facebook page
            </a>{" "}
            for more pictures, videos, and updates!
          </p>
        </section>
        <section className={styles.mapSection}>
          <h2>Visit us</h2>
          {/* <p>You can find us at:</p> */}
          <iframe
            className={styles.map}
            title="Ling's Wing Chun map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2903.0422066581214!2d-73.63911478461951!3d43.31337588235649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89dfd1c3c254ddd1%3A0xc7e8b15dcf0bbb1c!2sThe%20Shirt%20Factory%2C%2071%20Lawrence%20St%2C%20Glens%20Falls%2C%20NY%2012801!5e0!3m2!1sen!2sus!4v1584728883767!5m2!1sen!2sus"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </section>
      </div>
      <section className={styles.address}>
        <h2>Get in touch</h2>
        {/* <ContactForm /> */}
        <address>
          Ling's Wing Chun Kung Fu Academy <br />
          The Shirt Factory building <br />
          71 Lawrence Street, Garden Level #003 <br />
          Glens Falls, N.Y. 12801 <br />
          Phone: 518-796-6015 <br />
          Email: lingswingchun@gmail.com
        </address>
      </section>
      <section className={styles.info}>
        <p className={styles.info}>
          Please call Sifu Sam for more information about pricing and
          scheduling. Private lessons and gift certificates are also available.
        </p>
        <p>
          We also have special program rates for Girls and Boys Scout Troops,
          school trips, and other group organizations.
        </p>
        <p>
          During inclement weather (heavy snow, ice storms, or other weather
          emergencies) the school will be closed. Please contact Sifu Sam
          (phone, text, email) for school closing confirmation if unsure.
        </p>
      </section>
    </article>
  );
};

export { ContactUsPage };
